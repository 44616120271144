
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

* {
    font-family: 'Roboto Condensed', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    aspect-ratio: 16/9;
    background-color: #f6bd60;
}

.hover {
    cursor: pointer;
}
