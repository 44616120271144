.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.floating1 {  
    animation-name: floating1;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating1 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 25px); }
    to   { transform: translate(0, -0px); }    
}

.floating2 {  
    animation-name: floating2;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating2 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 30px); }
    to   { transform: translate(0, -0px); }    
}

.floating3 {  
    animation-name: floating3;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating3 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}

img {
    -webkit-filter: drop-shadow(5px 5px 5px rgba(23, 27, 44, 0.333));
    filter:         drop-shadow(5px 5px 5px rgba(23, 27, 44, 0.333)); 
}

