
.elevate {
    text-shadow: 3px 1px 2px #31313157;
    
}

.elevate-box {
    box-shadow: 3px 1px 2px #31313157;
}

.birthday {
    background: url("/src/media/2021/birthday.png");
    background-size: cover;
    border: 1px solid black;
}

.can-overflow {
    overflow-y: auto !important;
}

.image-gallery {
}

.birthday-text {
    font-size: 72px;
    background: -webkit-linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}