@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.floating1 {  
    -webkit-animation-name: floating1;  
            animation-name: floating1;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@-webkit-keyframes floating1 {
    from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    65%  { -webkit-transform: translate(0, 25px); transform: translate(0, 25px); }
    to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }    
}

@keyframes floating1 {
    from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    65%  { -webkit-transform: translate(0, 25px); transform: translate(0, 25px); }
    to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }    
}

.floating2 {  
    -webkit-animation-name: floating2;  
            animation-name: floating2;
    -webkit-animation-duration: 2.5s;
            animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@-webkit-keyframes floating2 {
    from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    65%  { -webkit-transform: translate(0, 30px); transform: translate(0, 30px); }
    to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }    
}

@keyframes floating2 {
    from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    65%  { -webkit-transform: translate(0, 30px); transform: translate(0, 30px); }
    to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }    
}

.floating3 {  
    -webkit-animation-name: floating3;  
            animation-name: floating3;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@-webkit-keyframes floating3 {
    from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    65%  { -webkit-transform: translate(0, 15px); transform: translate(0, 15px); }
    to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }    
}

@keyframes floating3 {
    from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    65%  { -webkit-transform: translate(0, 15px); transform: translate(0, 15px); }
    to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }    
}

img {
    -webkit-filter: drop-shadow(5px 5px 5px rgba(23, 27, 44, 0.333));
    filter:         drop-shadow(5px 5px 5px rgba(23, 27, 44, 0.333)); 
}



.elevate {
    text-shadow: 3px 1px 2px #31313157;
    
}

.elevate-box {
    box-shadow: 3px 1px 2px #31313157;
}

.birthday {
    background: url(/static/media/birthday.046e0981.png);
    background-size: cover;
    border: 1px solid black;
}

.can-overflow {
    overflow-y: auto !important;
}

.image-gallery {
}

.birthday-text {
    font-size: 72px;
    background: -webkit-linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

* {
    font-family: 'Roboto Condensed', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    aspect-ratio: 16/9;
    background-color: #f6bd60;
}

.hover {
    cursor: pointer;
}

